@import "../../../styles//variables";

.wrap {
  position: relative;
  width: 100%;
  padding: 165px 0 85px;

  .container {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    gap: 3%;
  }

  .title {
    font-weight: 800;
    line-height: 120%;
    margin-bottom: 30px;
  }

  .text {
    color: $seccolor;
    font-weight: 300;
    line-height: 150%;
    margin-top: 15px;
  }

  .image {
    width: 100%;
    border-radius: 30px;
  }

  @media screen and (min-width: $land-769) {
    .container {
      grid-template-columns: repeat(3, 1fr);
    }

    .title {
      span {
        display: block;
      }
    }
  }

  @media screen and (max-width: $land-768) {
    padding: 40px 0;

    .container {
      gap: 30px;
    }

    .title {
      margin-bottom: 25px;
    }
  }
}