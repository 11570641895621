@import "../../../styles//variables";

.wrap {
  position: relative;
  width: 100%;
  padding: 60px 0;

  .container {
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    gap: 30px;
  }

  .title {
    font-weight: 800;
    line-height: 130%;
  }

  .text {
    line-height: 150%;
    margin-top: 10px;
  }

  .list {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 30px;

    &Item {
      position: relative;
      display: flex;
      gap: 25px;

      &Icon {
        width: 27px;
        height: 27px;
        flex-shrink: 0;
      }

      &Title {
        position: relative;
        color: $seccolor;
        font-weight: 400;
      }
    }

    .image {
      flex: 0 0 auto;

      img {
        width: 100%;
      }
    }
  }
  
  @media screen and (min-width: $land-768) {
    .item {
      max-width: 525px;
      width: 50%;
    }

    .list + .image {
      margin-top: 65px;
    }

    .image + .list {
      margin-top: 50px;
    }
  }
  
  @media screen and (max-width: $land-768) {
    padding: 40px 0;

    .container {
      flex-direction: column;
      gap: 15px;
    }

    .item {
      display: flex;
      flex-direction: column;

      &First {
        .list {
          order: 99;
        }

        .image {
          margin-top: 15px;
        }
      }

      &Sec {
      
        .list {
          order: -1;
          margin-top: 0;
          margin-bottom: 15px;
        }
      }
    }

    .list {
      margin-top: 25px;
      gap: 15px;
  
      &Item {
        gap: 15px;
        border: 0;

        &Sec {
          .list {
            order: -1;
          }
        }
  
        &Text {
          margin-top: 15px;
        }
      }
    }
  }
}