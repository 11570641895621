@import "../../../styles//variables";

.wrap {
  position: relative;
  width: 100%;
  padding: 85px 0;

  .row {
    display: flex;

    &Left {
      width: 53%;
      background-color: rgba(35, 42, 54, 0.80);
      backdrop-filter: blur(9.5px);
      display: flex;
      justify-content: center;
      flex-direction: column;
      color: #fff;
      padding-left: 10%;
    }

    &Right {
      width: 47%;
      background: linear-gradient(125deg, #89C5C7 3.52%, #0B9CA0 96.48%);
      display: flex;
      align-items: flex-end;
      justify-content: center;

      img {
        max-width: 537px;
        width: 77%;
        max-height: 408px;
        height: 94.5%;

      }
    }
  }

  .title {
    font-style: normal;
    font-weight: 800;
    line-height: 100%;
  }

  .text {
    font-weight: 300;
    line-height: 150%;
    margin-top: 30px;
  }

  .list {
    max-width: 1052px;
    padding: 0 20px;
    margin: 35px auto 0;

    &Item {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 30px;

      &:not(:last-child) {
        margin-bottom: 30px;
      }

      &Title {
        font-weight: 600;
        color: $seccolor;
        font-family: $inter;
        flex-shrink: 0;
      }

      &Text {
        font-weight: 300;
        color: $seccolor;
        font-family: $inter;
        max-width: 631px;
        width: 100%;
      }
    }
  }
  @media screen and (min-width: $land-769) {

    .text, .title, .subtitle {
      max-width: 530px;
      // width: 50%;
    }
  }

  @media screen and (max-width: $land-768) {
    padding: 40px 0;

    .row {
      flex-direction: column;

      &Left {
        padding: 30px 20px;
      }

      &Left, &Right {
        width: 100%;
      }
    }

    .list {
      &Item {
        flex-direction: column;
      }
    }

    // .title, .subtitle, .center {
    //   text-align: center;
    // }
  }
}