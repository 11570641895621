@import "../../../styles//variables";

.wrap {
  position: relative;
  width: 100%;
  padding: 85px 0 170px;

  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 30px;
    background: linear-gradient(94deg, #00989D 41.98%, #01727A 91.68%);
    padding: 35px;

    .title {
      max-width: 385px;
      line-height: 120%;
    }

    &Item {

      &.list {
        margin-top: 0;
        
        .listItem {
          background: transparent;
          flex-direction: column;
          gap: 5px;

          .listItemIndex, .listItemTitle {
            font-weight: 800;
          }
        }
      }
    }
  }

  .title {
    color: #fff;
    font-weight: 800;
  }

  .list {
    margin-top: 35px;
    display: flex;
    justify-content: space-between;
    gap: 15px;

    &Item {
      display: flex;
      align-items: center;
      gap: 30px;
      border-radius: 12px;
      border-radius: 12px;
      background: linear-gradient(94deg, #00989D 41.98%, #01727A 91.68%);
      padding: 7px 10px;
      width: 30%;
      max-width: 350px;

      &Index {
        display: flex;
        flex-shrink: 0;
        color: #fff;
        font-size: 40px;
        font-style: normal;
        font-weight: 300;
        line-height: 120%; 
      }

      &Title {
        color: #fff;
        font-size: 20px;
        font-style: normal;
        font-weight: 300;
        line-height: 150%;
        white-space: nowrap;
      }
    }

    &Title {
      color: #FFF;
      font-family: $inter;
      font-size: 40px;
      font-style: normal;
      font-weight: 300;
      line-height: 120%; 
    }

    &Text {
      color: #FFF;
      font-family: $inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 300;
      line-height: 150%;
    }
  }
  
  @media screen and (max-width: $land-767) {
    padding: 40px 0 80px;

    .row {
      flex-direction: column;

      &Item {
        width: 100%;
        text-align: center;

        &.list {
          .listItem {
            padding-left: 10px;
          }
        }
      }
    }

    .list {
      margin-top: 15px;
      gap: 10px;
      flex-direction: column;
  
      &Item {
        gap: 15px;
        width: 100%;
        max-width: unset;
        padding: 10px 15px 10px 130px;
  
        &Index {
          width: auto;
          font-size: 25px;
        }
  
        &Title {
          font-size: 14px;
        }
      }
    }
  }
}