@import "../../../styles//variables";

.wrap {
  position: relative;
  width: 100%;
  padding: 85px 0;

  .container, .grid {
    position: relative;
    display: grid;
    grid-template-columns: 1fr; 
    gap: 20px;
  }

  .title {
    color: $seccolor;
    font-weight: 500;
    line-height: 150%;

    span {
      display: block;
    }
  }

  .text {
    color: $seccolor;
    font-weight: 300;
    line-height: 150%;
    margin-top: 15px;
  }

  .item {
    border-radius: 20px;
  }

  .gridItem {
    border-radius: 30px;
    background: #FFF;
    padding: 30px 20px;
  }

  @media screen and (min-width: $land-769) {

    .container, .grid {
      grid-template-columns: repeat(2, 1fr);
      gap: 36px;
    }
    .grid {
      gap: 34px;

      &Item:last-child {
        background: linear-gradient(180deg, #E3E6EC 0%, #E1E3E8 100%);
        grid-column: span 2; 
      }
    }
    .item {
      max-width: 590px;
    }
  }

  @media screen and (max-width: $land-768) {
    padding: 40px 0;
  }
}