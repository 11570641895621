@import "../../../styles//variables";

.wrap {
  position: relative;
  width: 100%;
  padding: 165px 0 85px;

  .container {
    position: relative;
    display: grid;
    gap: 15px;
    overflow: hidden;
  }

  .title {
    color: $colored;
    font-weight: 800;
    line-height: 130%;
  }

  .text {
    color: $seccolor;
    font-weight: 300;
    line-height: 150%;
    margin-top: 30px;
  }

  .subtitle {
    font-weight: 500;
    line-height: 150%;
  }

  .item {
    border-radius: 20px;
    padding: 30px 20px;
    color: #fff;

    &:nth-child(even) {
      .text {
        color: #fff;
      }
    }

    &.itemFirst {
      padding-left: 0;
      padding-bottom: 27px;
      padding-top: 0;
    }

    &.itemSec {
      background: #003032;
    }

    &.itemThird {
      background: #FFF;

      .subtitle {
        color: $seccolor;
      }

      .text {
        color: $seccolor;
      }
    }

    &.itemFour {
      background: linear-gradient(118deg, #76BFC1 15.84%, #1AA1A5 67.26%);
    }
  }

  @media screen and (min-width: $land-769) {

    .container {
      grid-template-columns: repeat(2, 1fr);
      gap: 30px;
    }
    .item {
      border-radius: 30px;
      padding: 60px 40px;
    }
  }

  @media screen and (max-width: $land-768) {
    padding: 90px 0 40px;
  }
}