@import "../../../styles//variables";

.wrap {
  position: relative;
  width: 100%;
  padding: 85px 0;

  .container {
    position: relative;
    display: flex;
    justify-content: space-between;
    gap: 30px;
  }

  .title {
    font-weight: 800;
    line-height: 130%;
    margin-bottom: 25px;
  }

  .text {
    color: $seccolor;
    font-weight: 300;
    line-height: 150%;
    margin-top: 15px;
  }

  &.de {
    .title {
      font-size: 50px;
    }
  }

  @media screen and (min-width: $land-769) {
    .item {
      max-width: 525px;
      width: 50%;
    }
  }

  @media screen and (max-width: $land-768) {
    padding: 40px 0;

    .container {
      flex-direction: column;
      gap: 30px;
    }

    .title {
      margin-bottom: 15px;
    }

    &.de {
      .title {
        font-size: 30px;
      }
    }
  }
}