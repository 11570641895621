@import "../../../styles//variables";

.wrap {
  position: relative;
  width: 100%;
  padding: 85px 0;

  .title {
    font-weight: 800;
    line-height: 120%;
    margin-bottom: 30px;
  }

  @media screen and (max-width: $land-768) {
    padding: 40px 0;
  }
}