@import './_variables';

@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Manrope:wght@200..800&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

.font-105-70 {
    font-family: $manrope;
    font-size: 105px;
}

.font-65-40 {
    font-family: $manrope;
    font-size: 65px;
}

.font-60-35, .font-60-30, .font-60-40 {
    font-family: $manrope;
    font-size: 60px;
}

.font-40-35, .font-40-30, .font-40-20 {
    font-family: $manrope;
    font-size: 40px;
}

.font-30-25 {
    font-family: $manrope;
    font-size: 30px;
}

.font-30-18 {
    font-family: $manrope;
    font-size: 30px;
}

.font-25-18, .font-25-20 {
    font-family: $inter;
    font-size: 25px;
}

.font-20-18 {
    font-family: $manrope;
    font-size: 20px;
}

.font-20 {
    font-family: $inter;
    font-size: 20px;
}

.font-17-14 {
    font-family: $inter;
    font-size: 17px;
    line-height: 150%;
    font-weight: 300;
}

.font-16-14 {
    font-family: $inter;
    font-size: 16px;
    line-height: 150%;
    font-weight: 300;
}

.font-14 {
    font-family: $inter;
    font-size: 14px;
    line-height: 150%;
    font-weight: 300;
}

@media screen and (max-width: $land-767) {

    .font-105-70 {
        font-size: 70px;
    }

    .font-65-40, .font-60-40 {
        font-size: 40px;
    }
    
    .font-60-35, .font-40-35 {
        font-size: 35px;
    }
    
    .font-60-30, .font-40-30 {
        font-size: 30px;
    }
    
    .font-30-25 {
        font-size: 25px;
    }
    
    .font-25-20 {
        font-size: 20px;
    }
    
    .font-30-18, .font-20-18, .font-25-18 {
        font-size: 18px;
    }
    
    .font-16-14, .font-17-14 {
        font-size: 14px;
    }
}