@import "../../../styles//variables";

.wrap {
  position: relative;
  width: 100%;
  padding: 85px 0;

  .container {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .title {
    font-weight: 800;
    line-height: 100%;
  }

  @media screen and (max-width: $land-768) {
    padding: 40px 0;

    .container {
      gap: 30px;
    }
  }
}