$colored: #00989D;
$seccolor: #232A36;
$maintext: #fff;

$opensans: "Open Sans", sans-serif;
$inter: "Inter", sans-serif;
$manrope: "Manrope", sans-serif;

$max-width: 1920px;
$main-width: 1220px;
$land-992: 992px;
$land-769: 769px;
$land-768: 768px;
$land-767: 767px;
$minwidth: 375px;