@import "../../../styles//variables";

.wrap {
  position: relative;
  width: 100%;
  padding: 165px 0 85px;

  .container {
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .title {
    font-style: normal;
    font-weight: 800;
    line-height: 130%;
  }

  .subtitle {
    color: $colored;
    font-weight: 800;
    line-height: 120%;
  }

  .text {
    color: $seccolor;
    font-weight: 300;
    line-height: 150%;
    margin-top: 30px;
  }
  @media screen and (min-width: $land-769) {

    .container {
      padding-top: 90px;
      min-height: 588px;
    }

    .text, .title, .subtitle {
      max-width: 525px;
    }

    .abs {
      position: absolute;
      top: 0;
      max-width: 500px;
      width: 100%;
      z-index: -1;
    }
  }

  @media screen and (min-width: $land-992) {
    .text, .title, .subtitle {
      width: 50%;
    }
    .abs {
      max-width: 686px;
      right: -50px;
    }
  }

  @media screen and (max-width: $land-768) {
    padding: 90px 0 40px;

    .title, .subtitle, .center {
      text-align: center;
    }

    .abs {
      width: 100%;
      height: auto;
      max-width: 100%;
      order: 1;
      margin-top: 60px;
    }
    .text {
      margin-top: 30px;
      order: 2;
    }
  }
}

:global(body.ar) {
  @media screen and (min-width: $land-769) {
    .wrap {
      .abs {
        right: auto;
        left: -50px;
      }
    }
  }
}