@import "../../../styles//variables";

.wrap {
  position: relative;
  width: 100%;
  padding: 155px 0 85px;

  .container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 50px;
  }

  .title {
    font-weight: 800;
    line-height: 120%;
  }

  .text {
    color: $seccolor;
    font-weight: 300;
    line-height: 150%;
    margin-top: 15px;
  }

  .abs {
    img {
      width: 100%;
    }
  }

  @media screen and (min-width: $land-769) {

    .item, .abs {
      max-width: 555px;
      width: 50%;
    }
  }

  @media screen and (max-width: $land-768) {
    padding: 90px 0 40px;

    .container {
      flex-direction: column;
      gap: 20px;
    }
  }
}