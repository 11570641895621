@import "../../../styles//variables";

.wrap {
  position: relative;
  width: 100%;
  padding: 85px 0;

  .container {
    position: relative;
    display: flex;
    grid-template-columns: repeat(3, 38.7%, 34.2%, 24.5%);
    gap: 15px;
  }

  .abs {
    position: absolute;
  }

  .item {

    &Left {
      display: flex;
      flex-direction: column;
      gap: 15px;

      .innerItem {
        background-color: $seccolor;
        position: relative;
        border-radius: 30px;
        min-height: 353px;
        padding: 30px 40px;
        color: $colored;
        display: flex;
        align-items: flex-end;
        font-weight: 800;

        .abs {
          top: 40px;
          right: 60px;
          max-width: 183px;
          max-height: 185px;
        }

        &Sec {
          align-items: flex-start;

          .abs {
            top: unset;
            bottom: 0;
            right: 0;
            max-width: 338px;
            max-height: 289px;
          }
        }
      }
    }

    &Center {
      background: linear-gradient(180deg, #8DC6C8 0%, #00989D 100%);
      position: relative;
      border-radius: 30px;
      padding: 30px 40px;
      color: #fff;
      display: flex;
      align-items: flex-end;
      font-weight: 800;

      & > div {
        position: relative;
      }

      .abs {
        right: 28px;
        bottom: 0;
        max-width: 358px;
        max-height: 649px;
      }
    }

    &Right {
      display: flex;
      flex-direction: column;
      gap: 9px;

      & > div {
        position: relative;
      }

      .innerItem {
        background-color: #fff;
        position: relative;
        border-radius: 30px;
        padding: 25px 30px;
        color: $seccolor;
        display: flex;
        align-items: flex-end;
        font-weight: 800;
        min-height: 230px;

        &.innerItemFirst, &.innerItemSec {

          .abs {
            left: 50%;
            transform: translateX(-50%);
            top: 29px;
            max-width: 161px;
            max-height: 110px;
          }
        }

        &.innerItemSec {
          .abs {
            max-width: 138px;
            max-height: 138px;
          }
        }

        &.innerItemLast {
          .abs {
            top: 0;
            right: 0;
            max-width: 171px;
            max-height: 230px;
          }
        }
      }
    }
  }

  @media screen and (min-width: $land-769) {
    .item {

      &Left {
        max-width: 473px;
        width: 38.7%;
      }

      &Center {
        max-width: 418px;
        width: 34.2%;
      }

      &Right {
        max-width: 299px;
        width: 24.5%;
      }
    }
  }

  @media screen and (max-width: $land-769) {
    padding: 0 0 40px;

    .container {
      flex-direction: column;
    }

    .item {
      &Left {
        .innerItem {
          min-height: 170px;
          padding: 20px;

          .abs {
            top: 15px;
            right: 20px;
            max-width: 136px;
            max-height: 136px;
          }

          &Sec {
          align-items: flex-end;
            .abs {
              top: unset;
              bottom: 0;
              right: 0;
              max-width: 181px;
              max-height: 155px;
            }
          }
        }
      }

    &Center {
      padding: 20px;
      min-height: 170px;

      .abs {
        right: 37px;
        max-width: 100px;
        max-height: 170px;
      }
    }

    &Right {
      .innerItem {
        padding: 20px;
        min-height: 170px;

        &.innerItemFirst, &.innerItemSec {

          .abs {
            max-width: 130px;
            max-height: 91px;
          }
        }

        &.innerItemSec {
          .abs {
            max-width: 94px;
            max-height: 94px;
          }
        }

        &.innerItemLast {
          .abs {
            max-width: 194px;
            max-height: 158px;
          }
        }
      }
    }
    }
  }
}