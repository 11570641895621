@import "../../../styles//variables";

.wrap {
  position: relative;
  width: 100%;
  padding: 60px 0;

  .container {
    overflow: hidden;
  }

  .title {
    font-weight: 800;
    line-height: 150%;
  }

  .list {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 60px;

    &Item {
      position: relative;
      display: flex;
      justify-content: space-between;
      width: 100%;
      border-top: 1px solid rgba(0, 0, 0, 0.30);
      border-bottom: 1px solid rgba(0, 0, 0, 0.30);

      &:nth-child(even) {
        flex-direction: row-reverse;

        .listItemTitle, .listItemText {
          margin-left: 68px;
        }
      }

      &Title {
        position: relative;
        color: $seccolor;
        font-weight: 600;
      }
  
      &Text {
        margin-top: 15px;
        color: $seccolor;
        max-width: 525px;
      }
    }

    .imgWrap {
      flex: 0 0 auto;

      img {
        width: 100%;
      }
    }

    .textBlock {
      flex: 1 1 auto;
      padding: 40px 0;
    }
  }
  
  @media screen and (min-width: $land-768) {

    .row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 30px;
    }
    .title, .text {
      max-width: 525px;
      width: 50%;
    }
  }
  
  @media screen and (max-width: $land-768) {
    padding: 60px 0;

    .title {
      max-width: unset;
      margin-bottom: 25px;
    }

    .list {
      margin-top: 25px;
      gap: 30px;
  
      &Item {
        flex-direction: column;
        gap: 30px;
        border: 0;
  
        &:nth-child(even) {
          flex-direction: column;
  
          .listItemTitle, .listItemText {
            margin-left: 0;
          }
        }
  
        &Title {
          max-width: unset;
        }
    
        &Text {
          margin-top: 15px;
          max-width: unset;
        }

        .textBlock {
          padding: 0;
        }
      }
    }
  }
}

:global(body.ar) {
  @media screen and (min-width: $land-768) {
    .wrap .listItem:nth-child(even) {
      .listItemTitle, .listItemText {
        margin-left: 0;
        margin-right: 68px;
      }
    }
  }
}