@import '../../../styles/variables';

.accordion {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .item {
    background-color: transparent;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #DEDEDE;
    background: #F4F6FA;
    transition: all 1s ease; 

    &.itemActive {
      .icon {
        transform: rotate(90deg);
      }

      .content {
        margin-top: 15px;
      }
    }
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 150%;
    color: $seccolor;
    font-family: $inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
    width: 100%;
    background: transparent;
    text-align: left;
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    flex-shrink: 0;
    cursor: pointer;
    transition: all 0.4s linear;
  }

  .content {
    color: $seccolor;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%;
    // padding-right: 16%;
    overflow: hidden;
    height: 0;
    transition: all 0.4s ease;

    .title {
      margin-bottom: 15px;
    }
    
    p:not(:last-child) {
      margin-bottom: 10px;
    }

    ul {
      padding-left: 20px;
      li {
        list-style-type: disc;
      }
    }
  }

  &.bordered {
    gap: 20px;
    
    .item {
      padding: 0 0 0 30px;
      border-radius: 2px;
      border: 0;
      border-left: 8px solid $colored;
      background: transparent;
      transition: all 1s ease; 
  
      &.itemActive {
        .icon {
          transform: rotate(90deg);
        }
  
        .content {
          margin-top: 15px;
        }
      }
    }
  
    // .title {
    // }
  
    .icon {
      display: none;
    }
  
    .content {
      color: $seccolor;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 150%;
      overflow: hidden;
      height: 0;
      transition: all 0.4s ease;
      
      p:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }

  &.short {
    .item {
      padding: 20px;
    }
  }

  @media (min-width: $land-768) {

    &.short {
      .content {
        max-width: 782px;
      }
    }
  }

  @media (max-width: $land-768) {
    .item {
      padding: 15px 10px 15px 20px;
    }

    .title {
      column-gap: 20px;
      text-align: left;
    }

    .content {
      padding-right: 30px;
    }
  }
}