* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #F4F6FA;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  max-width: 1260px;
  padding: 0 20px;
  margin: 0 auto;
  height: 100%;
}

.absolute {
  position: absolute;
}

.title {
  background: linear-gradient(137deg, #01727A 29.98%, #00989D 61.19%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.Dropdown-control {
  cursor: pointer;
  font-style: normal!important;
  font-size: 16px!important;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  background-color: transparent!important;
  border: none!important;
  color: $seccolor!important;
  text-transform: uppercase!important;
  padding: 0 10px!important;
  display: flex;
  align-items: center;
}

.Dropdown-root {
  position: relative;
}
.Dropdown-menu {
  background-color: white;
  border: 1px solid #ccc;
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
}
.Dropdown-arrow-wrapper {
  display: flex;
  align-items: center;
  margin-left: 7px;
}
.Dropdown-arrow {
  display: inline-block;
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgMTIgOCIgZmlsbD0ibm9uZSI+CiAgPHBhdGggZD0iTTEgMUw2LjE1MTUyIDZMMTEgMSIgc3Ryb2tlPSIjMDAwMDAwIiBzdHJva2Utd2lkdGg9IjEuNSIvPgo8L3N2Zz4=");
  background-repeat: no-repeat;
  width: 10px;
  height: 6px;
  transition: all 0.4s ease;
  background-size: cover;
}
.Dropdown-option {
  box-sizing: border-box;
  color: rgba(51, 51, 51, 0.8);
  cursor: pointer;
  display: block;
  padding: 8px 10px;
}
.is-open .Dropdown-arrow {
  transform: rotate(180deg);
}

.mt-btn {
  margin-top: 50px;
}

.mob_only {
  display: none!important;
}

.ovf-hidden {
  overflow: hidden;
}

.zoomOut {
  animation: zoomOut 0.5s linear;
}

@keyframes zoomOut {
  from {
    transform: scale(1.2);
  }
  to {
    transform: scale(1);
  }
}

.account-page {
  padding-top: 90px;
}

.ru.main-page {
  .smallerTitle {
    font-size: 80px;
  }
}

@media screen and (max-width: 767px) {

  .desk_only {
    display: none!important;
  }

  .mob_only {
    display: block!important;
  }

  .mt-btn {
    margin-top: 35px;
  }

  .account-page {
    padding-top: 60px;
  }

  // .fr.main-page {
  //   .title {
  //     font-size: 55px;
  //   }
  // }

  .ru.main-page {
    .title {
      font-size: 50px;
    }
  }
}