@import "../../../styles//variables";

.wrap {
  position: relative;
  width: 100%;
  padding: 180px 0 85px;

  .container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 50px;
  }

  .title {
    font-weight: 800;
    line-height: 140%;
  }

  .text {
    color: $seccolor;
    font-weight: 300;
    line-height: 150%;
    margin-top: 30px;
  }

  .abs {
    img {
      width: 100%;
    }
  }

  @media screen and (min-width: $land-769) {
    .container {
      padding: 0 20px 0 0;
      background-color: #fff;
    }

    .item, .abs {
      max-width: 525px;
      width: 50%;
    }
  }

  @media screen and (max-width: $land-768) {
    padding: 80px 0 40px;

    .container {
      flex-direction: column-reverse;
      gap: 20px;
      padding: 0;
    }

    .item {
      padding: 20px;
      text-align: center;
    }
    .text {
      text-align: left;
    }
  }
}