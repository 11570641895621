@import '../../../styles/_variables';

.button {
  text-decoration: none;
  padding: 8px 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: $colored;
  color: #fff;
  font-family: $opensans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -0.32px;
  text-transform: capitalize;
  border-radius: 5px;

  @media (max-width: 768px) {
      border-width: 1px;
      letter-spacing: -0.2px;
  }
}