@import '../../../styles/variables';

.wrap {
  position: relative;
  width: 100%;
  padding: 95px 0;

  .container {
    display: flex;
    gap: 35px;
  }
  
  .text {
    margin-top: 30px;
  }

  .tab {
    &sContent {
      max-width: 650px;
      width: 100%;
      margin-top: -5px;
    }

    &Buttons {
      width: 280px;
      flex-shrink: 0;

      button {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 20px;
        border: none;
        cursor: pointer;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        background-color: transparent;
        border-radius: 5px;
        margin-bottom: 10px;
        color: $seccolor;
        border: 1px solid rgba(35, 42, 54, 0.50);
        letter-spacing: -0.32px;

        &:last-child {
          margin-bottom: 0;
        }

        &.tabActive {
          color: #fff;
          font-weight: 700;
          background-color: $colored;
          border-color: $colored;
        }
      }
    }

    &Item {
      display: none;
      gap: 35px;

      &.tabItemActive {
        display: flex;
      }

      &Title {
        font-weight: 800;
        line-height: 100%;
        // margin-bottom: 29px;
      }

      &Text {
        color: #313131;
        font-size: 17px;
        font-style: normal;
        font-weight: 300;
        line-height: 150%;
        // margin-bottom: 30px;
        padding-right: 30px;
      }

      .inner {
        display: flex;
        flex-direction: column;
      }

      .mt { 
        margin-top: 40px;
      }
    }
  }

  .list {
    display: grid;
    grid-template-columns: 1fr;
    gap: 15px;
    margin-top: 30px;

    &Item {
      color: #000;
      font-family: $inter;
      font-size: 17px;
      font-style: normal;
      font-weight: 300;
      line-height: 150%;
      display: flex;
      align-items: flex-start;
      gap: 15px;

      &Text {

        strong {
          font-weight: 500;
        }
      }

      &Icon {
        width: 30px;
        height: 30px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  @media (max-width: $land-768) {
    padding: 50px 0;

    .container {
      flex-direction: column;
      padding-top: 40px;
    }

    .tab {
      &sContent {
        margin-top: 0;
      }

      &Item {
        flex-direction: column;
      }

      &Title {
        font-size: 25px;
      }

      &Text {
        font-size: 14px;
        padding-right: 0;
      }
  
      &Buttons {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 12px 20px;
        width: 100%;
  
        button {
          font-size: 16px;
          line-height: 110%;
          margin: 0;
          min-height: 68px;
        }
      }
  
      &Item {
  
        &Title {
          display: none;
        }
      }
    }

    .list {
      width: 100%;
      grid-template-columns: 1fr;
      margin-bottom: 30px;
    }
  }
}